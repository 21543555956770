import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecureDownloadDirective } from './util/secure-download.directive';

@NgModule({
  declarations: [SecureDownloadDirective],
  imports: [CommonModule],
  exports: [SecureDownloadDirective], // Export the directive so it can be used in other modules
})
export class SharedModule {}
